<template>
  <div class="main">
    <p>Les bienfaits de l'Ayurvéda débarquent prochainement à Grenoble !</p>
    <p style="margin-top: 3rem">Découvrez votre profil ayurvédique en quelques secondes&nbsp;:</p>
    <button class="cta" v-if="showCta" @click="$emit('start-simulation'); showCta = false">C'est parti !</button>
  </div>
</template>

<script>
import {Options, Vue} from "vue-class-component";

@Options({
  emits: ['start-simulation']
})
export default class HomePage extends Vue {
  showCta = true;
}
</script>

<style scoped>

.main {
  padding: 0 1rem;
}


p {
  font-size: 20px;
  line-height: 1.6;
  font-weight: 500;
}

.cta {
  padding: 12px 24px;
  border-radius: 6px;
  background-color: #4cacea;
  border: 3px solid #419bd9;
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 1rem;
}

</style>