
import {Options, Vue} from "vue-class-component";
import HelloWorld from "./components/HelloWorld.vue";
import Mock from "@/components/Mock.vue";
import Header from "@/components/Header.vue";
import HomePage from "@/components/HomePage.vue";
import Simulator from "@/components/Simulator.vue";

@Options({
  components: {
    Simulator,
    HomePage,
    HelloWorld,
    Mock,
    Header,
  },
})
export default class App extends Vue {

  showSim = false;

  lol() {
    this.showSim = true;
  }
}
