import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "simu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HomePage = _resolveComponent("HomePage")!
  const _component_Simulator = _resolveComponent("Simulator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_HomePage, { onStartSimulation: _ctx.lol }, null, 8, ["onStartSimulation"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showSim)
        ? (_openBlock(), _createBlock(_component_Simulator, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}