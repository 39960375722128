<template>
  <div class="wrap">
    <div class="title">🚧</div>
  </div>
</template>

<script>
import {Vue} from "vue-class-component";

export default class Mock extends Vue {

}
</script>

<style scoped>
.wrap {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 48px;
}
</style>