<template>
  <div class="sim">
    <div class="loading" v-if="loading">
      <img class="logo" src="@/assets/spinner.svg">
    </div>

    <div class="result" v-if="!loading">
      <h2 class="result-title">Vous êtes <b class="result-text">{{ result }}</b>&nbsp;!</h2>
      <ConfettiExplosion
          :force="1"
          :stage-height="1200"
          :particle-count="250"
          :colors="colors"/>
    </div>

    <button class="btn" v-if="!loading" @click="restart()">Recommencer</button>
  </div>
</template>

<script>
import {Options, Vue} from "vue-class-component";
import ConfettiExplosion from "vue-confetti-explosion/src/ConfettiExplosion.vue";

@Options({
  components: {ConfettiExplosion}
})
export default class Simulator extends Vue {

  profiles = ['Vata', 'Pitta', 'Kapha'];
  colors = ['#4b7af8', '#0d64a2', '#4cacea']
  loading = true;
  result = null;
  delay = 3000;

  restart() {
    this.loading = true;
    this.result = null;
    this.loadProfile();
  }

  loadProfile() {

    setTimeout(() => {
      this.result = this.getResult();
      this.loading = false;
    }, this.delay)
  }

  getResult() {
    const index = this.randomIntFromInterval(0, this.profiles.length - 1);
    let firstPick = this.profiles[index];

    if (this.randomIntFromInterval(0, 1) === 0) {
      firstPick += '-' + this.profiles.filter((p) => p !== firstPick)[this.randomIntFromInterval(0, this.profiles.length - 2)]
    }

    if (this.randomIntFromInterval(0, 1) === 0) {
      firstPick += '-' + this.profiles[this.randomIntFromInterval(0, this.profiles.length - 1)]
    }

    return firstPick;
  }

  randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  created() {
    this.loadProfile();
  }
}
</script>

<style scoped lang="scss">
.result {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-title {
    font-weight: 500;
    font-size: 22px;
  }

  &-text {
    font-size: 24px;
    font-weight: 800;
    color: #4cacea;
  }
}

.sim {
  margin: 0 1rem;
  padding: 1.25rem;
  background-color: white;
  border-radius: 6px;
  max-width: 600px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 4rem);
}

.loading {
  font-size: 18px;
}

.btn {
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #4cacea;
  border: 3px solid #419bd9;
  color: white;
  font-weight: 600;
  font-size: 15px;
  margin-top: 2rem;
}


</style>