<template>
  <header>
    <img class="logo" src="@/assets/spa-light.svg">
    <h1>Azurveda</h1>
  </header>
</template>

<script>
import {Vue} from "vue-class-component";

export default class Header extends Vue {
}
</script>

<style scoped>

header {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.logo {
  width: 80px;
  padding-bottom: 20px;
}

h1 {
  margin: 0;
  font-size: 62px;
}

@media only screen and (max-width: 600px) {

  .logo {
    width: 60px;
  }

  h1 {
    font-size: 42px;
  }
}


</style>